import React from 'react';
import { Helmet } from "react-helmet";
import Covid from './covid';

function laws(toggleDark) {
    return (
        <div className="">
        <Helmet>
            <title>შინაგანაწესი</title>
        </Helmet>
        <div className="text-about">
            <div className="content" style={{}}>
                <br />
                <h2><b>სსიპ აკადემიკოს ილია ვეკუას სახელობის ფიზიკა-მათემატიკის ქალაქ თბილისის #42 საჯარო სკოლის</b></h2><br />
                <h3 ><strong>შინაგანაწესი</strong></h3>
                <h3 >2021 წელი</h3>
                <p >&nbsp;</p>
                <div className="laws">
                    <p ><a href="./laws.pdf" download>შინაგანაწესის გადმოწერა</a></p>
                    <p >&nbsp;</p>
                    <p><strong>მუხლი 1. ზოგადი დებულებები</strong></p>
                    <ol>
                    <li>წინამდებარე სსიპ აკადემიკოს ილია ვეკუას სახელობის ფიზიკა-მათემატიკის ქალაქ თბილისის #42 საჯარო სკოლის (შემდგომში &ndash; ,,სკოლა&rdquo;) შინაგანაწესი (შემდგომში &ndash; ,,შინაგანაწესი&rdquo;) ვრცელდება სკოლაში დასაქმებულ პირებზე, მოსწავლეებსა და მათ მშობლებზე/კანონიერ წარმომადგენლებზე, ასევე სკოლის ტერიტორიაზე მყოფ ნებისმიერ პირზე და სავალდებულოა შესასრულებლად.</li>
                    <li>შინაგანაწესი წარმოადგენს სკოლასა და სკოლაში დასაქმებულებს შორის გაფორმებული შრომითი ხელშეკრულების განუყოფელ ნაწილს. დასაქმებული ვალდებულია გაეცნოს შინაგანაწესს და აღნიშნული დაადასტუროს საკუთარი ხელმოწერით.</li>
                    <li>შინაგანაწესი ხელმისაწვდომია ყველა დაინტერესებული პირისათვის.</li>
                    <li>სკოლა ვალდებულია შინაგანაწესი განათავსოს ყველასათვის თვალსაჩინო ადგილას.</li>
                    <li>დამრიგებელი ვალდებულია სასწავლო წლის დაწყებისას შინაგანაწესი გააცნოს მოსწავლესა და მის მშობელს/კანონიერ წარმომადგენელს. შინაგანაწესში განხორციელებული ცვლილების გაცნობა უნდა მოხდეს არაუგვიანეს 5 დღეში.</li>
                    <li>შინაგანაწესით განისაზღვრება:</li>
                    </ol>
                    <p>ა) სამუშაო კვირის ხანგრძლივობა, ყოველდღიური სამუშაოს დაწყებისა და დამთავრების დრო;</p>
                    <p>ბ) დასვენების ხანგრძლივობა;</p>
                    <p>გ) შრომის ანაზღაურების გაცემის დრო და წესი;</p>
                    <p>დ) ანაზღაურებადი შვებულების ხანგრძლივობა და მიცემის წესი;</p>
                    <p>ე) ანაზღაურების გარეშე შვებულების ხანგრძლივობა და მიცემის წესი;</p>
                    <p>ვ) შრომის პირობების დაცვის წესები;</p>
                    <p>ზ) წახალისებისა და პასუხისმგებლობის სახე და გამოყენების წესი;</p>
                    <p>თ) მატერიალური პასუხისმგებლობა;&nbsp;</p>
                    <p>ი) განცხადების/საჩივრის განხილვის წესი;</p>
                    <p>კ) სასწავლო პროცესის მარეგულირებელი ზოგადი ნორმები;</p>
                    <p>ლ) სკოლაში ჩაცმულობის წესი.</p>
                    <ol start="7">
                    <li>შინაგანაწესში გამოყენებულ ტერმინებს აქვთ შემდეგი მნიშვნელობა:</li>
                    </ol>
                    <p>ა) სკოლის ადმინისტრაცია - სკოლის საშტატო ნუსხით გათვალისწინებული პირები (გარდა მასწავლებლისა), ასევე სკოლაში შრომითი ხელშეკრულებით დასაქმებული პირები;</p>
                    <p>ბ) სკოლის დირექცია &ndash; სკოლის დირექტორი და მისი მოადგილე/მოადგილეები (ასეთის არსებობის შემთხვევაში);</p>
                    <p>გ) სასკოლო დრო &ndash; სასკოლო სასწავლო გეგმით განსაზღვრული პროცესის, აგრეთვე სკოლის მიერ ინიცირებული, ორგანიზებული, კონტროლირებული, დაფინანსებული ნებისმიერი ღონისძიების მიმდინარეობის დრო.</p>
                    <p>დ) უფლებამოსილი პირი &ndash; სკოლის დირექტორის ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტით განსაზღვრული პირი, რომელიც სკოლის ტერიტორიაზე, სასკოლო დროს უზრუნველყოფს უსაფრთხოებისა და საზოგადოებრივი წესრიგის დაცვას იმ სკოლებში, სადაც არ არის განაწილებული მანდატური (შემდგომში - მანდატური), ხოლო იმ სკოლებში სადაც არის მანდატური, ეხმარება მანდატურს საზოგადოებრივი წესრიგისა და უსაფრთხოების დაცვაში, მანდატურის სამსახურის უფროსის ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტით გათვალისწინებულ შემთხვევებში.</p>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 2. სამუშაო კვირის ხანგრძლივობა, სამუშაოს დაწყებისა და დამთავრების დრო</strong></p>
                    <ol>
                    <li>სკოლაში სამუშაო კვირა ხუთდღიანია (ორშაბათიდან პარასკევის ჩათვლით). სამუშაო დროის ხანგრძლივობა შეადგენს 40 საათს კვირაში, რაც გულისხმობს 8-საათიან 5 სამუშაო დღეს (სამუშაო დროში არ იგულისხმება შესვენების დრო და ამ მუხლის მე-2 პუნქტით გათვალისწინებული დასვენების დღეები.</li>
                    <li>შაბათ-კვირა და საქართველოს კანონმდებლობით დადგენილი უქმე დღეები არის დასვენების დღეები.</li>
                    <li>სამუშაო დროის დასაწყისია 09:00 საათი, ხოლო დასასრული - 18:00 საათი. სამუშაო დროის განმავლობაში შესვენების დროა 13:00 საათიდან 14:00 საათამდე.</li>
                    <li>დასაქმებული, რომელიც მეძუძური დედაა და კვებავს ერთ წლამდე ასაკის ბავშვს, მისი მოთხოვნის საფუძველზე ეძლევა დამატებითი შესვენება დღეში არანაკლებ 1 (ერთი) საათისა, რომელიც შეუძლია გამოიყენოს სამუშაო დღის ნებისმიერ მონაკვეთში. აღნიშნული ითვლება სამუშაო დროში და ანაზღაურდება.</li>
                    <li>სკოლაში დასაქმებულებისთვის ამ მუხლისაგან განსხვავებული სამუშაო კვირის ხანგრძლივობა, შესვენების, დასვენებისა და სამუშაო დრო შეიძლება განისაზღვროს მხარეთა შორის გაფორმებული შრომითი ხელშეკრულებით.</li>
                    <li>სკოლის მიერ ინიცირებული, ორგანიზებული, კონტროლირებული, დაფინანსებული ნებისმიერი ღონისძიებისთვის საჭირო დრო, რომელიც ემთხვევა უქმე საათებს შეიძლება ანაზღაურდეს სხვა დროს დამატებითი დასვენების ხარჯზე სკოლის დირექციასთან შეთანხმებით.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 3.&nbsp; სამუშაო დროის აღრიცხვა</strong></p>
                    <ol>
                    <li>დასაქმებულთა სამუშაო დროის ყოველდღიური აღრიცხვა წარმოებს ჟურნალის ან ტექნიკური საშუალებების გამოყენებით.</li>
                    <li>ყველა დასაქმებული ვალდებულია სამუშაოზე გამოცხადებისა და სამუშაო ადგილის დატოვების რეგისტრაცია ასახოს ჟურნალში ხელმოწერის მეშვეობით/რეგისტრაციის გავლით. იკრძალება სხვა დასაქმებულის ნაცვლად ხელის მოწერა/რეგისტრაციის გავლა.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 4. სამუშაო ადგილზე დაგვიანებით გამოცხადებისა და გამოუცხადობლობის შემთხვევაში შეტყობინების წესი</strong></p>
                    <ol>
                    <li>წინასწარ ცნობილი მიზეზით, სამუშაო ადგილზე გამოუცხადებლობის შემთხვევაში, დასაქმებული ვალდებულია წინასწარ (წინა დღეს, განსაკუთრებულ შემთხვევებში კი სამუშაოს დაწყებამდე მინიმუმ 1 (ერთი) საათით ადრე), გააფრთხილოს სკოლის დირექტორი/დირექტორის მოადგილე გამოუცხადებლობის შესახებ, სამუშაო ადგილზე არყოფნის სავარაუდო პერიოდის თაობაზე, შესაბამისი აუცილებლობისა და მიზეზების მითითებით.</li>
                    <li>გადაუდებელი აუცილებლობის (სამედიცინო ან ოჯახური პირობები) გამო სამუშაო ადგილზე გამოუცხადებლობა ან სამუშაო დროის განმავლობაში სამუშაო ადგილის დატოვების შემთხვევაში, დასაქმებული ვალდებულია გონივრულ ვადაში, შესაძლებლობისთანავე აცნობოს სკოლის დირექტორს/დირექტორის მოადგილეს გამოუცხადებლობის/სამუშაო დროის განმავლობაში სამუშაო ადგილის დატოვების შესახებ, შესაბამისი აუცილებლობისა და მიზეზების მითითებით.</li>
                    <li>სკოლის დირექტორი/დირექტორის მოადგილე აკონტროლებს დასაქმებულთა სამუშაო ადგილზე გამოცხადებას, სამუშაო დროის განმავლობაში სამუშაო ადგილის დატოვებას და სამუშაო ადგილზე გამოუცხადებლობას. არასაპატიოდ გაცდენისა ან/და დაგვიანების შემთხვევაში დასაქმებულს ჩამოერთმევა წერილობითი ახსნა-განმარტება.</li>
                    <li>დასაქმებულის სამუშაო ადგილზე გამოუცხადებლობის/სამუშაო დროის განმავლობაში სამუშაო ადგილის საპატიო მიზეზით დატოვების შემთხვევაში დასაქმებულზე არ გავრცელდება დისციპლინური პასუხისმგებლობის ზომები, თუმცა, მას ხელფასიდან დაუკავდება გაცდენილი სამუშაო დროის შესაბამისი ანაზღაურება.</li>
                    <li>არასაპატიო მიზეზით სამუშაო ადგილზე გამოუცხადებლობა ან სამუშაო დროის განმავლობაში სამუშაო ადგილის დატოვება განიხილება დისციპლინურ გადაცდომად და დასაქმებულზე გავრცელდება დისციპლინური პასუხისმგებლობის ზომები წინამდებარე შინაგანაწესით დადგენილი წესით. გარდა ამისა, დასაქმებულს ხელფასიდან დაუკავდება გაცდენილი სამუშაო დროის შესაბამისი ანაზღაურება.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 5.&nbsp; შრომის ანაზღაურების გაცემის დრო და წესი </strong></p>
                    <p>შრომის ანაზღაურება გაიცემა ყოველთვიურად, ყოველი &nbsp;თვის &nbsp;30 რიცხვამდე. ანაზღაურების გაცემა ხდება უნაღდო ანგარიშსწორების ფორმით, დასაქმებულის პირად საბანკო ანგარიშზე თანხის ჩარიცხვის გზით.</p>
                    <p><strong>მუხლი 6. ანაზღაურებადი შვებულების ხანგრძლივობა და მიცემის წესი</strong></p>
                    <ol>
                    <li>დასაქმებულს (გარდა მასწავლებლისა) უფლება აქვს ისარგებლოს ანაზღაურებადი შვებულებით წელიწადში 24 სამუშაო დღით.</li>
                    <li>მასწავლებლის შვებულების ხანგრძლივობა განისაზღვრება სკოლასა და მასწავლებელს შორის გაფორმებული ხელშეკრულებით.</li>
                    <li>შვებულებით სარგებლობის მოთხოვნით დასაქმებული სკოლის დირექტორს მიმართვს წერილობითი განცხადებით, 5 (ხუთი) სამუშაო დღით ადრე (გარდა ობიექტური გარემოებებისა) შვებულება ფორმდება სკოლის დირექტორის ინდივიდუალურ ადმინისტრაციულ - სამართლებრივი აქტით.</li>
                    <li>ანაზღაურებადი შვებულებით სარგებლობასა და საშვებულებო ანაზღაურებასთან დაკავშირებული სხვა საკითხები რეგულირდება მხარეთა შორის გაფორმებული შრომითი ხელშეკრულებითა და მოქმედი კანონმდებლობის შესაბამისად.</li>
                    </ol>
                    <p><strong>მუხლი 7. ანაზღაურების გარეშე შვებულებების ხანგრძლივობა და მიცემის წესი</strong></p>
                    <ol>
                    <li>დასაქმებულს უფლება აქვს, ისარგებლოს ანაზღაურების გარეშე შვებულებით &ndash; წელიწადში სულ მცირე 15 კალენდარული დღით.</li>
                    <li>ანაზღაურების გარეშე შვებულების ხანგრძლივობისა და მიცემის წესთან დაკავშირებული საკითხები რეგულირდება მხარეთა შორის გაფორმებული შრომითი ხელშეკრულებითა და მოქმედი კანონმდებლობის შესაბამისად.</li>
                    <li>შვებულებით სარგებლობის მოთხოვნით დასაქმებული სკოლის დირექტორს მიმართვს წერილობითი განცხადებით, 5 (ხუთი) სამუშაო დღით ადრე (გარდა ობიექტური გარემოებებისა) შვებულება ფორმდება სკოლის დირექტორის ინდივიდუალურ ადმინისტრაციულ - სამართლებრივი აქტით.</li>
                    </ol>
                    <p><strong>მუხლი 8. შვებულება ორსულობის, მშობიარობისა და ბავშვის მოვლის გამო</strong></p>
                    <ol>
                    <li>დასაქმებულს თავისი მოთხოვნის საფუძველზე ეძლევა შვებულება ორსულობის, მშობიარობისა და ბავშვის მოვლის გამო &ndash; 730 კალენდარული დღის ოდენობით, რომელიც დასაქმებულს თავისი შეხედულებისამებრ შეუძლია გადაანაწილოს ორსულობისა და მშობიარობის შემდგომ პერიოდებზე.</li>
                    <li>ორსულობის, მშობიარობისა და ბავშვის მოვლის გამო შვებულების ანაზღაურების საკითხი განისაზღვრება &bdquo;ორსულობის, მშობიარობისა და ბავშვის მოვლის, ასევე ახალშობილის შვილად აყვანის გამო შვებულებების ანაზღაურების წესის&ldquo;დამტკიცების თაობაზე&ldquo; საქართველოს შრომის, ჯანმრთელობისა და სოციალური დაცვის მინისტრის 2006 წლის 25 აგვისტოს №231/ნ ბრძანებით.</li>
                    </ol>
                    <p><strong>მუხლი 9. შრომის პირობების დაცვის წესები</strong></p>
                    <ol>
                    <li>სკოლა ვალდებულია შექმნას შრომის ჯანსაღი და უსაფრთხო გარემო-პირობები, შეასრულოს შრომის უსაფრთხოების დაცვის წესები, განახორციელოს ღონისძიებები უსაფრთხოებისა და სანიტარულ-ჰიგიენური ნორმების დაცვის მიზნით.</li>
                    <li>სკოლა ვალდებულია დაიცვას საქართველოს განათლების, მეცნიერების, კულტურისა და სპორტის მინისტრის 2019 წლის 29 იანვრის N06/ნ ბრძანებით დამტკიცებული &bdquo;ზოგადსაგანმანათლებლო დაწესებულებაში უსაფრთხოებისა და საზოგადოებრივი წესრიგის დაცვის წესი და პირობები&ldquo; (შემდგომში - &bdquo;უსაფრთხოების წესი&ldquo;).</li>
                    <li>სკოლის ტერიტორიაზე სასკოლო დროს უსაფრთხოებისა და საზოგადოებრივი წესრიგის დაცვის მიზნით სკოლაში განთავსებულია:</li>
                    </ol>
                    <p>ა) სკოლის შიდა (ყველა დერეფანი, შესასვლელი (ფოიე) და კიბის უჯრედი) და გარე პერიმეტრის დათვალიერების მიზნით ვიდეოსათვალთვალო სისტემა;</p>
                    <p>ბ) ხანძარსაწინააღმდეგო ინვენტარი;</p>
                    <p>გ) ევაკუაციის გეგმა, რომელიც გამოკრული უნდა იყოს თვალსაჩინო ადგილზე ყველა სართულზე.</p>
                    <ol start="4">
                    <li>დასაქმებულის, მოსწავლის, მშობლისა და სკოლის ტერიტორიაზე მყოფი პირებისათვის შესასრულებლად სავალდებულოა უსაფრთხოებისა და შრომის პირობების დაცვის წესები, რომლებიც გათვალისწინებულია საქართველოს კანონმდებლობით.</li>
                    <li>დასაქმებული ვალდებულია:</li>
                    </ol>
                    <p>ა) გაუფრთხილდეს მასზე მინდობილ სკოლის ქონებას;</p>
                    <p>ბ) მოწესრიგებული ჰქონდეს თავისი სამუშაო ადგილი და დაიცვას სანიტარულ-ჰიგიენური ნორმები.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>მუხლი 10. დასაქმებულის წახალისების სახეები და გამოყენების წესი</strong></p>
                    <p>1.პროფესიულ საქმიანობაში მოპოვებული წარმატებისათვის ან/და შრომითი ხელშეკრულებით გათვალისწინებული ვალდებულებების მაღალი პროფესიონალიზმით შესრულებისათვის ან/და დიდი მოცულობის,განსაკუთრებული სირთულის ან/და მნიშვნელობის სამუშაოს შესრულებისათვის, ხანგრძლივი და კეთილსინდისიერი სამსახურისთვის ან/და საგანმანათლებლო პროცესში განსაკუთრებულად თავის გამოჩენისათვის, სკოლა უფლებამოსილია დასაქმებულის მიმართ გამოიყენოს წახალისების შემდეგი ფორმები:</p>
                    <p>ა) მადლობის გამოცხადება;</p>
                    <p>ბ) სიგელით დაჯილდოება;</p>
                    <p>გ) ფასიანი საჩუქრით დაჯილდოება;</p>
                    <p>დ) ერთჯერადი (ფულადი) პრემიის გაცემა;</p>
                    <p>ე) დისციპლინური პასუხისმგებლობის ზომის ვადაზე ადრე მოხსნა.</p>
                    <ol start="2">
                    <li>დასაქმებულის მიმართ შეიძლება ერთდროულად გამოყენებული იქნეს წახალისების რამდენიმე ფორმა.</li>
                    <li>დასაქმებულის წახალისების შესახებ გადაწყვეტილება ფორმდება დირექტორის ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტით.</li>
                    <li>დირექტორის მოადგილე/შესაბამისი საგნობრივი კათედრა/პედაგოგიური საბჭო/სამეურვეო საბჭო/მატერიალურ-ტექნიკური უზრუნველყოფის კოორდინატორი უფლებამოსილია წერილობით მიმართოს სკოლის დირექტორს დასაქმებულის/დასაქმებულების წახალისების თხოვნით.</li>
                    <li>დასაქმებულის მოტივაციის ამაღლების მიზნით, სკოლის დირექტორის ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტით შესაძლოა კალენდარულ წელიწადში ორჯერ გაიცეს სადღესასწაულო პრემია.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 11. მოსწავლის წახალისების ფორმები და გამოყენების წესი</strong></p>
                    <ol>
                    <li>დაწყებითი, საბაზო ან/და საშუალო საფეხურის წარმატებით დასრულებისათვის, ეროვნულ ან/და საერთაშორისო ოლიმპიადებში წარმატებით მონაწილეობისათვის, საგანმანათლებლო პროცესში განსაკუთრებულად თავის გამოჩენისათვის, სანიმუშო სწავლისათვის ან სხვა გარემოების არსებობისას დირექტორის გადაწყვეტილებით სკოლა უფლებამოსილია მოსწავლეთა მიმართ გამოიყენოს წახალისების შემდეგი ფორმები:</li>
                    </ol>
                    <p>ა) მადლობის გამოცხადება;</p>
                    <p>ბ) სიგელით დაჯილდოება;</p>
                    <p>გ) ფასიანი საჩუქრით დაჯილდოება.</p>
                    <ol start="2">
                    <li>მოსწავლის მიმართ შეიძლება ერთდროულად გამოყენებულ იქნეს წახალისების რამდენიმე ფორმა.</li>
                    <li>წახალისების შესახებ გადაწყვეტილება ფორმდება სკოლის დირექტორის ინდივიდუალური ადმინისტრაციულ - სამართლებრივი აქტით და ინახება მოსწავლის პირად საქმეში.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 12. დისციპლინური დევნა</strong></p>
                    <ol>
                    <li>დისციპლინური დევნა არის სკოლის მიერ ჩატარებულ ღონისძიებათა ერთობლიობა, რომელთა მიზანია ჩადენილი დისციპლინური გადაცდომის გამოვლენა.</li>
                    <li>სასკოლო დისციპლინა დაცული უნდა იქნეს იმ მეთოდების მეშვეობით, რომლებიც ეფუძნება თავისუფლებისა და ღირსების პატივისცემას.</li>
                    <li>დისციპლინური დევნა და სახდელი უნდა იყოს გონივრული, დასაბუთებული და პროპორციული. დისციპლინური სახდელის დაკისრებისას გათვალისწინებული უნდა იქნეს ჩადენილი დისციპლინური გადაცდომის შინაარსი, სიმძიმის ხარისხი და შედეგი, რაც პირდაპირ მიზეზობრივ კავშირშია ჩადენილ დისციპლინურ გადაცდომასთან.</li>
                    <li>დისციპლინური დევნა შეიძლება განხორციელდეს მხოლოდ შინაგანაწესით გათვალისწინებულ შემთხვევებში და დადგენილი წესით, ჯეროვანი და სამართლიანი პროცედურით. დაუშვებელია ისეთი დისციპლინური სახდელის გამოყენება, რომელიც ლახავს პირის პატივსა და ღირსებას.</li>
                    <li>დისციპლინური დევნისას სკოლა ვალდებულია განუმარტოს პირს მის მიერ ჩადენილი დისციპლინური გადაცდომის არსი.</li>
                    <li>ერთი დისციპლინური გადაცდომისათვის შეიძლება დაკისრებულ იქნეს დისციპლინური სახდელის მხოლოდ ერთი სახე.</li>
                    <li>დაუშვებელია სკოლის მიერ დისციპლინური სახდელის დაკისრება იმ ქმედებისთვის, რომელიც პირმა ჩაიდინა სასკოლო დროისგან თავისუფალ დროს ან სკოლის ტერიტორიის გარეთ, გარდა იმ ქმედებისა, რომელიც პირდაპირ ან ირიბად ზიანს აყენებს სკოლის ან/და მოსწავლის ინტერესებს.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 13. დასაქმებულის მიერ დისციპლინური გადაცდომა და პასუხისმგებლობის სახეები</strong></p>
                    <ol>
                    <li>დასაქმებულის დისციპლინური გადაცდომაა:</li>
                    </ol>
                    <p>ა) სამსახურებრივ მოვალეობათა ან/და შინაგანაწესის შეუსრულებლობა ან არაჯეროვანი შესრულება;</p>
                    <p>ბ) სამსახურებრივი მოვალეობისადმი დაუდევარი დამოკიდებულება;</p>
                    <p>გ) დასაქმებულის, მოსწავლის ან სკოლის დისკრედიტაციისაკენ მიმართული უღირსი საქციელი, ზნეობრივ - ეთიკური ნორმების დარღვევა;</p>
                    <p>დ) ქმედება, რომელიც ლახავს სკოლის რეპუტაციას/ავტორიტეტს;</p>
                    <p>ე) მასწავლებლის მიერ მასწავლებლის პროფესიული ეთიკის კოდექსის დარღვევა;</p>
                    <p>ვ) მოსწავლეზე/დასაქმებულზე ფსიქოლოგიური ზეწოლა;</p>
                    <p>ზ) მოსწავლის/დასაქმებულის პირად ცხოვრებაში ჩარევა, გარდა მოსწავლის მშობლისათვის/კანონიერი წარმომადგენლისათვის მოსწავლი პირადი ცხოვრების შესახებ ინფორმაციის მიწოდებისა;</p>
                    <p>თ) კანონმდებლობის მოთხოვნათა დარღვევით პერსონალური მონაცემების გამჟღავნება ან/და კონფიდენციალობის დარღვევა;</p>
                    <p>ი) სკოლისათვის შეგნებულად ან დაუდევრობით ზიანის მიყენება ან ასეთი ზიანის წარმოშობის საშიშროების შექმნა;</p>
                    <p>კ) სკოლის ტექნიკის, აღჭურვილობისა ან/და დოკუმენტების უნებართვოდ ან არასათანადოდ გამოყენება ან/და სკოლის ტერიტორიის გარეთ გატანა;</p>
                    <p>ლ) სკოლის ტერიტორიაზე ან სასკოლო დროს უსაფრთხოების წესების დარღვევა;</p>
                    <p>მ) სკოლის ტერიტორიაზე თამბაქოს/ელექტრონული სიგარეტის მოხმარება;</p>
                    <p>ნ) მოსწავლის/დასაქმებულის/მოსწავლის მშობლის/კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის სიტყვიერი ან/და ფიზიკური შეურაცხყოფა ან/და მოსწავლის ფიზიკური დასჯა;</p>
                    <p>ო) სამსახურში დაგვიანება ან გაუფრთხილებლად სამუშაოს გაცდენა ან სამუშაო დროის განმავლობაში სამუშაო ადგილის გაუფრთხილებლად დატოვება;</p>
                    <p>პ) სკოლის დოკუმენტაციის (საკლასო ჟურნალი, კათედრის პორტფოლიო, სხდომის ოქმები და სხვა) წარმოების წესის დარღვევა;</p>
                    <p>ჟ) საგაკვეთილო პროცესის შეგნებულად მიტოვება ან ჩაშლა ან ჩაშლის მცდელობა, გაკვეთილზე დაგვიანება, საგაკვეთილო პროცესიდან მოსწავლეების ჯგუფურად გაშვება.</p>
                    <p>რ) დისციპლინური გადაცდომის შესახებ ინფორმაციის დამალვა;</p>
                    <p>ს) სკოლაში კონფლიქტური სიტუაციების შექმნა;</p>
                    <p>ტ) საკლასო ჟურნალის დაზიანება, დაკარგვა.</p>
                    <ol start="2">
                    <li>გარდა ამ მუხლის პირველი პუნქტით გათვალისწინებული დისციპლინური გადაცდომებისა, დასაქმებულის დისციპლინური გადაცდომაა უსაფრთხოების წესის ნორმების დარღვევა, მათ შორის:</li>
                    </ol>
                    <p>ა) საგანგებო სიტუაციების დროს:</p>
                    <p>ა.ა) კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ა.ბ) სკოლის ადმინისტრაციის წარმომადგენლის/დირექტორის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ა.გ) უფლებამოსილი პირის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ბ) საზოგადოებრივი ჯანმრთელობისათვის რისკის შემცველი გადამდები დაავადების შემთხვევის დროს:</p>
                    <p>ბ.ა) კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ბ.ბ) სკოლის ადმინისტრაციის წარმომადგენლის/დირექტორის/სკოლის ექიმის/ექთნის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ბ.გ) უფლებამოსილი პირის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>გ) სუიციდური აზრის/სუიციდის მცდელობის/სუიციდის დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის/სკოლის დირექტორის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>დ) თვითდაზიანების დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის/კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ე) ფეთქებადი ნივთიერებების სკოლაში აღმოჩენის დროს/სკოლაში ფეთქებადი ნივთიერებების არსებობის შესახებ შეტყობინების დროს კლასის დამრიგებლის/უფლებამოსილი პირის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ვ) მოსწავლის მიერ საქართველოს კანონმდებლობით აკრძალული ნივთის ქონის დროს უფლებამოსილი პირის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ზ) მოსწავლის/მასწავლებლის/სკოლის სხვა თანამშრომლის მიერ ალკოჰოლის ან/და ნარკოტიკული საშუალების, მისი ანალოგის, პრეკურსორის, ახალი ფსიქოაქტიური ნივთიერების, ფსიქოტროპული ნივთიერების, მისი ანალოგის ან ძლიერმოქმედი ნივთიერების შეტანის, რეალიზაციის, სკოლის ტერიტორიაზე მოხმარების ან/და მათი ზემოქმედების ქვეშ ყოფნის დროს:</p>
                    <p>ზ.ა) უფლებამოსილი პირის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ზ.ბ) სკოლის ადმინისტრაციის/მასწავლებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ზ.გ) იმ მასწავლებლის/სკოლის სხვა თანამშრომლის პასუხისმგებლობა, ვისაც აღმოაჩნდა ზემოაღნიშნული ნივთიერებები;</p>
                    <p>თ) იმ შემთხვევაში, როდესაც მოსწავლის მშობელი/კანონიერი წარმომადგენელი არ არის ინფორმირებული, მოსწავლის საგაკვეთილო პროცესზე გამოუცხადებლობის ან/და საპატიო მიზეზის გარეშე დაგვიანების დროს უფლებამოსილი პირის/საგნის მასწავლებლის/კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ი) შესაბამისი საფუძვლისა და ნებართვის გარეშე მოსწავლის მიერ საგაკვეთილო/სასწავლო პროცესის მიმდინარეობისას კლასის ან/და სკოლის დატოვების დროს საგნის მასწავლებლის/კლასის დამრიგებლის/სკოლის ადმინისტრაციის/უფლებამოსილი პირის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>კ) მასწავლებლის/დასაქმებულის მიერ მოსწავლის მიმართ ნებისმიერი სახის ძალადობის დროს;</p>
                    <p>ლ) მოსწავლის მიერ/მოსწავლის მიმართ ჩადენილი საქართველოს სისხლის სამართლის კოდექსითა და საქართველოს ადმინისტრაციულ სამართალდარღვევათა კოდექსით გათვალისწინებული ნებისმიერი სამართალდარღვევის დროს უფლებამოსილი პირი/სკოლის ადმინისტრაციის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>მ) ბულინგის/კიბერბულინგის დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის/კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ნ) მოსწავლის მიერ/მოსწავლის მიმართ განხორციელებული დისკრიმინაციის დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის/კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ო) მოსწავლის დესტრუქციული ქცევის დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>პ) მოსწავლის მიერ ფულის/ნივთის უნებართვოდ აღების, ფულის შეგროვების, ფულის/ნივთის სხვა მოსწავლისათვის იძულების ნებისმიერი ფორმით გამორთმევის დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის/კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ჟ) მოსწავლის/მასწავლებლის/სკოლის თანამშრომლის/სკოლის ქონების ხელყოფის დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის/კლასის დამრიგებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>რ) მოსწავლის/მოსწავლეების განცალკევების დროს უფლებამოსილი პირის/სკოლის ადმინისტრაციის წარმომადგენლის/ მასწავლებლის მიერ ვალდებულების შეუსრულებლობა;</p>
                    <p>ს) მანდატურის მიერ შედგენილ დარღვევის აღრიცხვის ფურცლებზე, შინაგანაწესის მე-20 მუხლით დადგენილ ვადაში, დირექციის მხრიდან შესაბამისი რეაგირების გაუტარებლობა;</p>
                    <p>ტ) სკოლის ადმინისტრაციის/სკოლის დირექციის/მასწავლებლების პასუხისმგებლობა იმ შემთხვევაში თუ მათ მიერ ადგილი ჰქონდა დაცული/კონფიდენციალური/პერსონალური ინფორმაციის/მონაცემების გაჟღერებას/გაცემას/არადანიშნულებისამებრ გამოყენებას;</p>
                    <p>უ) მრჩეველთა საბჭოს რეკომენდაციების შეუსრულებლობის შემთხვევაში დირექტორის მიერ განსაზღვრული, რეკომენდაციების შესრულებაზე ვალდებული პირის მიმართ;</p>
                    <p>ფ) დასაქმებულისათვის შეუფერებელი სხვა ქმედება.</p>
                    <ol start="3">
                    <li>დისციპლინური გადაცდომის ჩადენისათვის, დასაქმებულის მიმართ გამოიყენება შემდეგი დისციპლინური პასუხისმგებლობის სახე:</li>
                    </ol>
                    <p>ა) შენიშვნა;</p>
                    <p>ბ) გაფრთხილება;</p>
                    <p>გ) საყვედური;</p>
                    <p>დ) სასტიკი საყვედური;</p>
                    <p>ე) არაუმეტეს ათი სამუშაო დღის ხელფასის დაკავება;</p>
                    <p>ვ) სამსახურიდან გათავისუფლება.</p>
                    <ol start="4">
                    <li>დისციპლინური სახდელის სახით შენიშვნა შესაძლებელია გამოყენებულ იქნეს:</li>
                    </ol>
                    <p>ა) სამსახურში დაგვიანებისას;</p>
                    <p>ბ) სკოლის ტექნიკის, აღჭურვილობისა და დოკუმენტების უნებართვოდ ან არასათანადოდ გამოყენების ან/და სკოლის ტერიტორიის გარეთ გატანისას;</p>
                    <p>გ) სამსახურებრივი მოვალეობისადმი დაუდევარი დამოკიდებულებისას.</p>
                    <ol start="5">
                    <li>დისციპლინური სახდელის სახით გაფრთხილება შეიძლება გამოყენებულ იქნეს:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-4 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ დასაქმებულის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (შენიშვნა), რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით გაფრთხილების გამოყენება;</p>
                    <p>ბ) გაუფრთხილებლად სამუშაოს გაცდენისას ან სამუშაო დროის განმავლობაში სამუშაო ადგილის გაუფრთხილებლად დატოვება;</p>
                    <p>გ) სკოლის დოკუმენტაციის (საკლასო ჟურნალი, კათედრის პორტფოლიო, სხდომის ოქმების და სხვა) წარმოების წესის დარღვევისას;</p>
                    <p>დ) სკოლის ტერიტორიაზე თამბაქოს/ელექტრონული სიგარეტის მოხმარებისას;</p>
                    <p>ე) საკლასო ჟურნალის დაზიანებისას;</p>
                    <p>ვ) სკოლისათვის შეგნებულად ან დაუდევრობით ზიანის წარმოშობის საშიშროების შექმნისას.</p>
                    <ol start="6">
                    <li>დისციპლინური სახდელის სახით საყვედური შეიძლება გამოყენებულ იქნეს:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-5 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ დასაქმებულის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (გაფრთხილება) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით საყვედურის გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) სკოლაში კონფლიქტური სიტუაციების შექმნისას;</p>
                    <p>გ) მასწავლებლის პროფესიული ეთიკის კოდექსის დარღვევისას;</p>
                    <p>დ) დისციპლინური გადაცდომის შესახებ ინფორმაციის დამალვისას;</p>
                    <p>ე) საგაკვეთილო პროცესის შეგნებულად მიტოვების ან ჩაშლის ან ჩაშლის მცდელობისას;</p>
                    <p>ვ) დასაქმებულის ან მოსწავლის ან სკოლის დისკრედიტაციისაკენ მიმართული უღირსი საქციელი, ზნეობრივ - ეთიკური ნორმების დარღვევისას;</p>
                    <p>ზ) სკოლის ტერიტორიაზე ან სასკოლო დროს უსაფრთხოების წესების დარღვევისას;</p>
                    <p>თ) საკლასო ჟურნალის დაკარგვისას;</p>
                    <p>ი) ამ მუხლის მე-2 პუნქტით გათვალისწინებული დისციპლინური გადაცდომების შემთხვევაში;</p>
                    <p>კ) მრჩეველთა საბჭოს რეკომენდაციების შეუსრულებლობის შემთხვევაში დირექტორის მიერ განსაზღვრული, რეკომენდაციების შესრულებაზე ვალდებული პირის მიმართ;</p>
                    <p>ლ) უსაფრთხოების წესით გათვალისწინებული საგანმანათლებლო-საინფორმაციო კამპანიების ჩაუტარებლობის შემთხვევაში კამპანიის ჩატარებაზე ვალდებული პირ(ებ)ის მიმართ;</p>
                    <p>მ) უფლებამოსილი პირის მიერ ელექტრონულ საინფორმაციო ბაზაში ინფორმაციის შემთხვევითი ან უკანონო განადგურების, შეცვლის, გამჟღავნების ან ნებისმიერი სხვა უკანონო დამუშავებისათვის;</p>
                    <p>ნ) უსაფრთხოების წესის 37-ე მუხლით განსაზღვრული ელექტრონული საინფორმაციო ბაზის წარმოების წესის დარღვევისას;</p>
                    <p>ო) უსაფრთხოების წესის 42-ე მუხლით გათვალისწინებული სკოლის ვიდეოთვალთვალის სისტემის ადმინისტრირების წესის დარღვევისას;</p>
                    <p>პ) უფლებამოსილი პირის მიერ ყოველდღიური შეფასების კითხვარის შეუვსებლობისა და მანდატურის სამსახურისათვის არ გაგზავნის შემთხვევაში, ასევე უფლებამოსილი პირის მიერ სკოლის პერიმეტრის დაუთვალიერებლობის, როგორც არადისტანციურად, ისე დისტანციურად. (უსაფრთხოების წესის 63-ე მუხლით გათვალისწინებული მოთხოვნების შეუსრულებლობის შემთხვევაში);</p>
                    <p>ჟ) მოსწავლის მიერ სავარაუდო დარღვევის შესახებ, სკოლის მანდატურისგან შედგენილ რღვევის აღრიცხვის ფურცლებზე, ამ შინაგანაწესის 20-ე მუხლით დადგენილ ვადაში, დირექციის მხრიდან შესაბამისი რეაგირების არარსებობის შემთხვევაში შესაბამისი პირის მიმართ.</p>
                    <ol start="8">
                    <li>დისციპლინური სახდელის სახით სასტიკი საყვედური შეიძლება გამოყენებულ იქნეს:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-7 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მის მიმართ გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (საყვედური) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით სასტიკი საყვედურის გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) ისეთი ქმედების ჩადენისას, რომელიც ლახავს სკოლის რეპუტაციას/ავტორიტეტს;</p>
                    <p>გ) მოსწავლეზე/ დასაქმებულზე ფსიქოლოგიური ზეწოლისას;</p>
                    <p>დ) მოსწავლის/სკოლაში დასაქმებულის/მოსწავლის მშობლის ან კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის სიტყვიერი შეურაცხყოფისას;</p>
                    <p>ე) მოსწავლის პირად ცხოვრებაში ჩარევისას, გარდა მოსწავლის მშობლისათვის/კანონიერი წარმომადგენლისათვის მოსწავლი პირადი ცხოვრების შესახებ ინფორმაციის მიწოდებისა;</p>
                    <p>ვ) სკოლის ტერიტორიაზე ან სასკოლო დროს უსაფრთხოების წესების უხეში დარღვევისას;</p>
                    <p>ზ) სკოლის დოკუმენტაციის (საკლასო ჟურნალი, კათედრის პორტფოლიო და სხვა) წარმოების წესის დარღვევას, რამაც მნიშვნელოვანი ზიანი გამოიწვია.</p>
                    <p>თ) კანონმდებლობის მოთხოვნათა დარღვევით პერსონალური მონაცემების გამჟღავნება ან/და კონფიდენციალობის დარღვევა.</p>
                    <ol start="9">
                    <li>დისციპლინური სახდელის სახით არაუმეტეს ათი სამუშაო დღის ხელფასის დაკავება შეიძლება გამოყენებულ იქნეს დასაქმებულის მიერ შინაგანაწესის ამ მუხლის მე-8 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მის მიმართ გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (სასტიკი საყვედური) ან პირდაპირ, დისციპლინური პასუხისმგებლობის წინა ზომების გამოყენების გარეშე სკოლისათვის მატერიალური/ ქონებრივი ზიანის მიყენებისათვის.</li>
                    <li>დისციპლინური სახდელის სახით სამსახურიდან გათავისუფლება გამოიყენება იმ შემთხვევაში, თუ დისციპლინური პასუხისმგებლობის წინა ზომებმა შედეგი არ გამოიღო ან/და დასაქმებულის მიერ ჩადენილი გადაცდომის ხასიათიდან ან/და სიმძიმიდან გამომდინარე მიზანშეწონილია მისი გათავისუფლება. დისციპლინური პასუხისმგებლობის წინა ზომების გამოყენების გარეშე დასაქმებული სამსახურიდან პირდაპირ გათავისუფლდება:</li>
                    </ol>
                    <p>ა) მოსწავლის/დირექციის წევრის/დასაქმებულის/მოსწავლის მშობლის ან კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის ფიზიკური შეურაცხყოფისათვის;</p>
                    <p>ბ) სკოლის ტერიტორიაზე ალკოჰოლის ან/და ნარკოტიკული საშუალების, მისი ანალოგის, პრეკურსორის, ახალი ფსიქოაქტიური ნივთიერების, ფსიქოტროპული ნივთიერების, მისი ანალოგის ან ძლიერმოქმედი ნივთიერების შეტანის, რეალიზაციის, სკოლის ტერიტორიაზე მოხმარების ან/და მათი ზემოქმედების ქვეშ ყოფნის შემთხვევაში;</p>
                    <p>გ) საქართველოს კანონმდებლობის უხეში დარღვევისას, რომელიც პირდაპირ ან ირიბად ზიანს აყენებს სკოლის, მოსწავლის ან/და სკოლის ტერიტორიაზე მყოფი პირების ინტერესებს ან/და წინამდებარე შინაგანაწესის უხეში დარღვევის შემთხვევაში.</p>
                    <ol>
                    <li>ამ მუხლის მე-4-მე-10 პუნქტებით დადგენილი წესის გაუთვალისწინებლად, დასაქმებულს შესაძლებელია დაეკისროს ამავე მუხლის მე-3 პუნქტით დადგენილი დისციპლინური სახდელები კონკრეტული შემთხვევის ხასიათის, დასაქმებულისა და სკოლის ინტერესებიდან გამომდინარე.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 14. დასაქმებულთა (გარდა მასწავლებლების) დისციპლინური გადაცდომის განხილვაზე უფლებამოსილი ორგანო და დისციპლინური წარმოების წესი</strong></p>
                    <ol>
                    <li>დასაქმებულთა (გარდა მასწავლებლების) დისციპლინურ გადაცდომებს განიხილავს სკოლის დირექტორი. სკოლის დირექტორის გადაწყვეტილებით დასაქმებულთა დისციპლინური გადაცდომა შეიძლება განიხილოს დისციპლინურმა კომიტეტმა.</li>
                    <li>დისციპლინური წარმოების დაწყების საფუძველი შეიძლება იყოს:</li>
                    </ol>
                    <p>ა) მოსწავლის, მოსწავლის მშობლის/კანონიერი წარმომადგენლის, სკოლაში დასაქმებული პირის, უფლებამოსილი პირის, აგრეთვე, იმ პირის წერილობითი მიმართვა ან ინფორმაცია, რომლის სამსახურებრივ მოვალეობასაც წარმოადგენს სკოლაში საზოგადოებრივი წესრიგის დაცვის უზრუნველყოფა;</p>
                    <p>ბ) დისციპლინური გადაცდომის ფაქტის აღმოჩენა ან სხვა გარემოება, რომელიც ეჭვს იწვევს, რომ ადგილი აქვს დასაქმებულის მიერ დისციპლინური გადაცდომის ჩადენის ფაქტს, მათ შორის მასობრივი ინფორმაციის საშუალებებით ან სხვა გზით გავრცელებული ინფორმაცია, დასაქმებულის მიერ ისეთი ქმედების ჩადენის შესახებ, რომელიც შეიძლება დისციპლინურ გადაცდომად ჩაითვალოს.</p>
                    <ol start="3">
                    <li>დაუშვებელია სკოლის მიერ დასაქმებულისათვის (გარდა მასწავლებლების) დისციპლინური სახდელის დაკისრება იმ ქმედებისთვის, რომელიც მან სასკოლო დროისგან თავისუფალ დროს ან სკოლის ტერიტორიის გარეთ ჩაიდინა, გარდა იმ ქმედებისა, რომელიც პირდაპირ ან ირიბად ზიანს აყენებს სკოლის ან/და მოსწავლის ინტერესებს.</li>
                    <li>დასაქმებულის (გარდა მასწავლებლების) მიმართ დისციპლინური წარმოება შეიძლება დაიწყოს დისციპლინური გადაცდომის ჩადენიდან ან დისციპლინური გადაცდომის ჩადენის ფაქტის გამოვლენიდან არაუგვიანეს 1 (ერთი) წლისა.</li>
                    <li>დისციპლინური სახდელის დაკისრების ან არდაკისრების შესახებ ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტი გამოიცემა გადაცდომის თაობაზე ინფორმაციის მიღებიდან არაუგვიანეს 1 (ერთი) თვისა.</li>
                    <li>თუ საქმისათვის არსებითი მნიშვნელობის მქონე გარემოებათა დადგენისათვის აუცილებელია ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტის გამოსაცემად კანონმდებლობით გათვალისწინებულზე მეტი ვადა, ამ შემთხვევაში შესაძლებელია ადმინისტრაციული წარმოების გაზრდა, რომელიც არ უნდა აღემატებოდეს წარმოების დაწყებიდან 3 თვეს.</li>
                    <li>დასაქმებულის (გარდა მასწავლებლების) მიმართ დისციპლინური წარმოება მიმდინარეობს საქართველოს განათლებისა და მეცნიერების მინისტრის 2014 წლის 30 დეკემბრის №167/ნ ბრძანებით დამტკიცებული მასწავლებელთა დისციპლინური წარმოების წესის შესაბამისად.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 15.&nbsp; მასწავლებლის დისციპლინური წარმოების წესი</strong></p>
                    <p>მასწავლებლის მიმართ დისციპლინური წარმოება მიმდინარეობს საქართველოს განათლებისა და მეცნიერების მინისტრის 2014 წლის 30 დეკემბრის №167/ნ ბრძანებით დამტკიცებული მასწავლებელთა დისციპლინური წარმოების წესის შესაბამისად.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>მუხლი 16. მოსწავლის დისციპლინური გადაცდომა და პასუხისმგებლობის სახეები</strong></p>
                    <ol>
                    <li>მოსწავლის დისციპლინურ გადაცდომად ითვლება:</li>
                    </ol>
                    <p>ა) გაკვეთილზე არაერთგზის დაგვიანება;</p>
                    <p>ბ) გაკვეთილის ჩაშლა ან ჩაშლის მცდელობა;</p>
                    <p>გ) გაკვეთილის თვითნებურად მიტოვება;</p>
                    <p>დ) სასწავლო პროცესის დროს მობილური ტელეფონით ან/და ნებისმიერი სხვა სახის ტექნიკური საშუალებით არასასწავლო მიზნით სარგებლობა;</p>
                    <p>ე) სკოლისათვის მატერიალური/ქონებრივი ზიანის მიყენება ან ასეთი ზიანის წარმოშობის საშიშროების შექმნა;</p>
                    <p>ვ) მოსწავლის მიერ შინაგანაწესით დადგენილი ჩაცმულობის წესის დაუცველად სკოლის ტერიტორიაზე გამოცხადება, გარდა სკოლის მიერ წინასწარ განსაზღვრულ ღონისძიებაში მონაწილეობის მიღების მიზნით გამოცხადებისა;</p>
                    <p>ზ) მოსწავლის/სკოლაში დასაქმებულის/მოსწავლის მშობლის/კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის სიტყვიერი შეურაცხყოფა;</p>
                    <p>თ) მოსწავლის/სკოლაში დასაქმებულის/მოსწავლის მშობლის/კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის ფიზიკური შეურაცხყოფა;</p>
                    <p>ი) სკოლის ტერიტორიაზე მოსწავლის გამოცხადება შემდეგი ნივთებით:</p>
                    <p>ი.ა) თამბაქოს ნაწარმი/ელექტრონული სიგარეტი;</p>
                    <p>ი.ბ) ალკოჰოლური საშუალებები;</p>
                    <p>ი.გ) ნარკოტიკული საშუალებები და ნარკოტიკული საშუალებების მოხმარებისათვის საჭირო ნივთები;</p>
                    <p>ი.დ) ფსიქოტროპული საშუალებები, ექიმის შესაბამისი ნებართვის გარეშე;</p>
                    <p>ი.ე) ასაფეთქებელი და მომწამვლავი ნივთიერებები;</p>
                    <p>ი.ვ) ნებისმიერი სახის იარაღი, კასტეტი ან ბასრი ნივთი, რომელიც შეიძლება გამოყენებულ</p>
                    <p>იქნეს იარაღად;</p>
                    <p>ი.ზ) სანთებელა ან ასანთი;</p>
                    <p>ი.თ) აზარტული თამაშის საშუალებები;</p>
                    <p>ი.ი) უხამსობის ამსახველი ნივთები/საშუალებები;</p>
                    <p>ი.კ) ელექტროშოკური მოწყობილობა;</p>
                    <p>ი.ლ) ნებისმიერი სხვა ნივთი, რომელიც შეიძლება საფრთხეს უქმნიდეს სიცოცხლესა და ჯანმრთელობას.</p>
                    <p>კ) მოსწავლის დისკრიმინაცია;</p>
                    <p>ლ) მოსწავლის ან/და სკოლაში დასაქმებული პირის პირად ცხოვრებაში ჩარევა;</p>
                    <p>მ) ფულის ან სხვა ნივთების გამოძალვა;</p>
                    <p>ნ) მოსწავლის მიერ წინამდებარე პუნქტის &bdquo;ი&ldquo;ქვეპუნქტით განსაზღვრული ნივთის/ნივთების გამოყენება;</p>
                    <p>ო) სკოლაში დასაქმებული პირების კანონიერი მოთხოვნის დაუმორჩილებლობა;</p>
                    <p>პ) სკოლის ტერიტორიაზე სხვისი ნივთის უნებართვოდ აღება, დაკარგვა ან/და დაზიანება;</p>
                    <p>ჟ) სკოლის უსაფრთხოების წესების დარღვევა, რომელმაც ზიანი გამოიწვია;</p>
                    <p>რ) მოსწავლეთა ქცევის კოდექსის დარღვევა;</p>
                    <p>ს) მოსწავლის ან/და სკოლაში დასაქმებული პირის მიმართ ფსიქოლოგიური ზეწოლა;</p>
                    <p>ტ) სკოლის დოკუმენტაციის ხელყოფა;</p>
                    <p>უ) სკოლის საქმიანობისთვის განკუთვნილი კომპიუტერული მონაცემის მიღება ან/და კომპიუტერული სისტემის უნებართვოდ ან სკოლის ინტერესების საწინააღმდეგოდ გამოყენება;</p>
                    <p>ფ) არაფხიზელ მდგომარეობაში სკოლაში გამოცხადება;</p>
                    <p>ქ) მოსწავლისთვის შეუფერებელი სხვა ქმედების ჩადენა.</p>
                    <ol start="2">
                    <li>დისციპლინური გადაცდომის ჩადენისათვის მოსწავლის მიმართ გამოიყენება შემდეგი დისციპლინური პასუხისმგებლობის სახე:</li>
                    </ol>
                    <p>ა) შენიშვნა;</p>
                    <p>ბ) გაფრთხილება;</p>
                    <p>გ) მკაცრი გაფრთხილება;</p>
                    <p>დ) საყვედური;</p>
                    <p>ე) სასტიკი საყვედური;</p>
                    <p>ვ) სკოლიდან დროებით დათხოვნა;</p>
                    <p>ზ) სკოლისათვის სასარგებლო საქმიანობის დაკისრება;</p>
                    <p>თ) მოსწავლის სკოლიდან გარიცხვა (საშუალო საფეხურზე).</p>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 17. მოსწავლის მიერ დისციპლინური გადაცდომა და პასუხისმგებლობის სახეები</strong></p>
                    <ol>
                    <li>დისციპლინური სახდელის სახით შენიშვნა შესაძლოა გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) გაკვეთილზე ზედიზედ არაერთგზის დაგვიანება;</p>
                    <p>ბ) საგაკვეთილო პროცესის დროს მობილური ტელეფონით ან/და ნებისმიერი სხვა სახის ტექნიკური საშუალებით არასასწავლო მიზნით სარგებლობა;</p>
                    <p>გ) მოსწავლის მიერ სკოლის შინაგანაწესით დადგენილი ჩაცმულობის წესის (სამოსი, იერსახე და სხვა) დაუცველად სკოლის ტერიტორიაზე გამოცხადება. გამონაკლისი დაიშვება სკოლის მიერ წინასწარ განსაზღვრულ ღონისძიებებში მონაწილეობისას.</p>
                    <p>დ) სკოლის ტერიტორიაზე სხვისი ნივთის უნებართვოდ აღება;</p>
                    <p>ე) სკოლის ტერიტორიაზე მოსწავლის გამოცხადება შემდეგი ნივთებით:</p>
                    <p>ე.ა) თამბაქოს ნაწარმი/ელექტრონული სიგარეტი;</p>
                    <p>ე.ბ) სანთებელა ან ასანთი;</p>
                    <p>ე.გ) აზარტული თამაშის საშუალებები;</p>
                    <ol start="2">
                    <li>დისციპლინური სახდელის სახით გაფრთხილება შესაძლოა გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) ამ მუხლის პირველი პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მოსწავლის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (შენიშვნა) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით გაფრთხილების გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) გაკვეთილის ჩაშლა ან ჩაშლის მცდელობა;</p>
                    <p>გ) გაკვეთილის თვითნებურად მიტოვება;</p>
                    <p>დ) სკოლისათვის მატერიალური/ქონებრივი ზიანის მიყენება ან ასეთი ზიანის წარმოშობის საშიშროების შექმნა;</p>
                    <p>ე) სკოლაში დასაქმებული პირების კანონიერი მოთხოვნის დაუმორჩილებლობა;</p>
                    <p>ვ) მოსწავლის სიტყვიერი შეურაცხყოფა;</p>
                    <p>ზ) სკოლის ტერიტორიაზე მოსწავლის გამოცხადება შემდეგი ნივთებით:</p>
                    <p>ზ.ა) ალკოჰოლური საშუალებები;</p>
                    <p>ზ.ბ) უხამსობის ამსახველი ნივთები/საშუალებები.</p>
                    <p>თ) არაფხიზელ მდგომარეობაში სკოლაში გამოცხადება.</p>
                    <ol start="3">
                    <li>დისციპლინური სახდელის სახით მკაცრი გაფრთხილება შესაძლოა გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-2 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მოსწავლის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (გაფრთხილება) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით მკაცრი გაფრთხილების გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) სკოლის უსაფრთხოების წესების დარღვევა, რომელმაც ზიანი გამოიწვია;</p>
                    <p>გ) მოსწავლის ფიზიკური შეურაცხყოფა;</p>
                    <p>დ) მოსწავლის დისკრიმინაცია;</p>
                    <p>ე) მოსწავლის ან/და სკოლაში დასაქმებული პირის პირად ცხოვრებაში ჩარევა;</p>
                    <p>ვ) სკოლის დოკუმენტაციის ხელყოფა;</p>
                    <p>ზ) სკოლის ტერიტორიაზე სხვისი ნივთის დაკარგვა ან/და დაზიანება.</p>
                    <ol start="4">
                    <li>დისციპლინური სახდელის სახით საყვედური შესაძლოა გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-3 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მოსწავლის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (მკაცრი გაფრთხილება) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით საყვედურის გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) მოსწავლის ან/და სკოლაში დასაქმებული პირის მიმართ ფსიქოლოგიური ზეწოლა;</p>
                    <p>გ) სკოლის საქმიანობისთვის განკუთვნილი კომპიუტერული მონაცემის მიღება ან/და კომპიუტერული სისტემის უნებართვოდ ან სკოლის ინტერესების საწინააღმდეგოდ გამოყენება;</p>
                    <p>დ) მოსწავლის მიერ სკოლის ტერიტორიაზე ალკოჰოლური საშუალებების გამოყენება;</p>
                    <p>ე) სკოლის ტერიტორიაზე ექიმის შესაბამისი ნებართვის გარეშე, ფსიქოტროპული საშუალებებით მოსწავლის გამოცხადება.</p>
                    <ol start="5">
                    <li>დისციპლინური სახდელის სახით სასტიკი საყვედური შესაძლოა გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-4 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მოსწავლის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (საყვედური) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით სასტიკი საყვედურის გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) სკოლაში დასაქმებულის/მოსწავლის მშობლის/კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის სიტყვიერი შეურაცხყოფა;</p>
                    <p>გ) სკოლის ტერიტორიაზე მოსწავლის გამოცხადება შემდეგი ნივთებით:</p>
                    <p>გ.ა) ნებისმიერი სახის იარაღი, კასტეტი ან ბასრი ნივთი, რომელიც შეიძლება გამოყენებულ</p>
                    <p>იქნეს იარაღად;</p>
                    <p>გ.ბ) ელექტროშოკური მოწყობილობა;</p>
                    <p>გ.გ) ნებისმიერი სხვა ნივთი, რომელიც შეიძლება საფრთხეს უქმნიდეს სიცოცხლესა და ჯანმრთელობას.</p>
                    <ol start="6">
                    <li>დისციპლინური სახდელის სახით სკოლიდან დათხოვნა, დროებით, 5 სასწავლო დღემდე სკოლის დირექტორის მიერ შეიძლება გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-5 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მოსწავლის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (სასტიკი საყვედური) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით სასტიკი საყვედურის გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) სკოლაში დასაქმებულის/მოსწავლის მშობლის/კანონიერი წარმომადგენლის/სკოლის ტერიტორიაზე შემოსული გარეშე პირის ფიზიკური შეურაცხყოფა;</p>
                    <p>გ) მოსწავლის მიერ სკოლის ტერიტორიაზე ექიმის შესაბამისი ნებართვის გარეშე ფსიქოტროპული საშუალებების გამოყენება;</p>
                    <p>დ) სკოლის ტერიტორიაზე მოსწავლის გამოცხადება შემდეგი ნივთებით:</p>
                    <p>დ.ა) ნარკოტიკული საშუალებები;</p>
                    <p>დ.ბ) ასაფეთქებელი და მომწამლავი ნივთიერებები.</p>
                    <ol start="7">
                    <li>დისციპლინური სახდელის სახით სკოლიდან დათხოვნა, დროებით, 5-დან 10 სასწავლო დღემდე დისციპლინური კომიტეტის მიერ შეიძლება გამოყენებულ იქნეს შემდეგ შემთხვევებში:</li>
                    </ol>
                    <p>ა) ამ მუხლის მე-6 პუნქტით გათვალისწინებული დისციპლინური გადაცდომის განმეორებით ჩადენისას, თუ მოსწავლის მიმართ უკვე გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (სკოლიდან დროებით დათხოვნა - 5 სასწავლო დღემდე) ან შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის სისტემატურად ჩადენისას, რის გამოც მიზანშეწონილია მის მიმართ დისციპლინური სახდელის სახით სასტიკი საყვედურის გამოყენება დისციპლინური პასუხისმგებლობის წინა ზომის გამოყენების გარეშე;</p>
                    <p>ბ) ფულის ან სხვა ნივთების გამოძალვა;</p>
                    <p>გ) მოსწავლის მიერ სკოლის ტერიტორიაზე შემდეგი ნივთების გამოყენება:</p>
                    <p>გ.ა) ნებისმიერი სახის იარაღი, კასტეტი ან ბასრი ნივთი, რომელიც შეიძლება გამოყენებულ</p>
                    <p>იქნეს იარაღად;</p>
                    <p>გ.ბ) ელექტროშოკური მოწყობილობა;</p>
                    <p>გ.გ) ნარკოტიკული საშუალებები;</p>
                    <p>გ.დ) ნებისმიერი სხვა ნივთი, რომელიც შეიძლება საფრთხეს უქმნიდეს სიცოცხლესა და ჯანმრთელობას.</p>
                    <p>8.მოსწავლის სკოლიდანდროებით დათხოვნის შემთხვევაში დაუყოვნებლივ უნდა ეცნობოს მის მშობელს. სკოლა ვალდებულია მოსწავლე სკოლიდან დროებით დათხოვნის შემთხვევაში თავისი მზრუნველობის ქვეშ ჰყავდეს.</p>
                    <ol start="9">
                    <li>მოსწავლისთვის შეუფერებელი სხვა ქმედების ჩადენის ან მოსწავლეთა ქცევის კოდექსის დარღვევის შემთხვევაში მოსწავლეს შინაგანაწესის მე-16 მუხლის მე-2 პუნქტით დადგენილი დისციპლინური სახდელი დაეკისრება კონკრეტული შემთხვევის სიმძიმის გათვალისწინებით.</li>
                    <li>წინამდებარე შინაგანაწესის მე-16 მუხლის პირველი პუნქტით განსაზღვრული გადაცდომის ჩადენის შემთხვევაში, დისციპლინური სახდელის სახით, სასარგებლო საქმიანობის დაკისრება შეიძლება გამოყენებულ იქნეს, თუ სკოლა მიიჩნევს, რომ სასარგებლო საქმიანობის დაკისრებით მეტად არის შესაძლებელი მოსწავლის დისციპლინის გაუმჯობესება და განმეორებით დისციპლინური გადაცდომის ჩადენის პრევენცია.</li>
                    <li>დისციპლინური სახდელის სახით, საშუალო საფეხურზე მოსწავლის სკოლიდან გარიცხვა, სკოლის დისციპლინური კომიტეტის მიერ შეიძლება გამოყენებულ იქნეს:</li>
                    </ol>
                    <p>მოსწავლის მიერ შინაგანაწესით გათვალისწინებული დისციპლინური გადაცდომის ჩადენისას, თუ მის მიმართ გამოყენებულია დისციპლინური პასუხისმგებლობის წინა ზომა (სკოლიდან დროებით დათხოვნა 5-დან 10 სასწავლო დღემდე) ან პირდაპირ, დისციპლინური პასუხისმგებლობის წინა ზომების გამოყენების გარეშე, თუ მოსწავლის მიერ ჩადენილი ქმედება განზრახ არსებით ზიანს აყენებს სკოლის ინტერესებს, თუ საფრთხე ექმნება სხვა მისწავლი ან სკოლაში დასაქმებული პირის ჯანმრთელობას ან/და უსაფრთხოებას.</p>
                    <ol start="2">
                    <li>ამ მუხლის პირველი-მეშვიდე პუნქტებით დადგენილი წესის გაუთვალისწინებლად, მოსწავლეს შესაძლებელია დაეკისროს ამავე შინაგანაწესის მე-16 მუხლის მე-2 პუნქტით დადგენილი დისციპლინური სახდელები კონკრეტული შემთხვევის ხასიათის, მოსწავლისა და სკოლის ინტერესებიდან გამომდინარე.</li>
                    <li>დისციპლინური გადაცდომის ჩადენისათვის მოსწავლის მიმართ სკოლიდან დროებით დათხოვნის ან სკოლიდან გარიცხვის (საშუალო საფეხურზე) გამოყენების შემთხვევაში, აღნიშნულის თაობაზე სკოლამ დაუყოვნებლივ უნდა აცნობოს სსიპ სოციალური მომსახურების სააგენტოს.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 18. სახდელის დაკისრება ერთზე მეტი გადაცდომის ჩადენის დროს</strong></p>
                    <p>გადაცდომათა ერთობლიობის დროს, სკოლაში დასაქმებულ პირს/მოსწავლეს დაეკისრება უფრო მძიმე გადაცდომისთვის გათვალისწინებული სახდელი. გადაცდომათა ერთობლიობა გულისხმობს რამდენიმე გადაცდომის ჩადენას, რომლებიც გამოვლენილია, მაგრამ ჯერ არ მომხდარა მასზე დისციპლინური სახდელის დაკისრება.</p>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 19. მოსწავლეთა მიმართ დისციპლინური წარმოების წესი</strong></p>
                    <ol>
                    <li>მოსწავლეთა მიმართ დისციპლინური წარმოების დაწყების საფუძველი შეიძლება იყოს:</li>
                    </ol>
                    <p>ა) მოსწავლის, მოსწავლის მშობლის/კანონიერი წარმომადგენლის, დამრიგებლის, სკოლაში დასაქმებული სხვა პირის, მოსწავლეთა თვითმართველობის, აგრეთვე, იმ პირის წერილობითი მიმართვა ან ინფორმაცია, რომლის სამსახურებრივ მოვალეობასაც წარმოადგენს სკოლაში საზოგადოებრივი წესრიგის დაცვის უზრუნველყოფა;</p>
                    <p>ბ) დისციპლინური გადაცდომის ფაქტის აღმოჩენა ან სხვა გარემოება, რომელიც ეჭვს იწვევს, რომ ადგილი აქვს მოსწავლის მიერ დისციპლინური გადაცდომის ჩადენის ფაქტს, მათ შორის მასობრივი ინფორმაციის საშუალებებით გავრცელებული ინფორმაცია, მოსწავლის მიერ ისეთი ქმედების ჩადენის შესახებ, რომელიც შეიძლება დისციპლინურ გადაცდომად ჩაითვალოს.</p>
                    <ol start="2">
                    <li>დაუშვებელია სკოლის მიერ მოსწავლის დისციპლინური დევნა იმ ქმედებისთვის, რომელიც მან სკოლის ტერიტორიის გარეთ ჩაიდინა.</li>
                    <li>წინამდებარე შინაგანაწესის მე-16 მუხლის პირველი პუნქტით გათვალისწინებულ დისციპლინურ გადაცდომებს განიხილავს სკოლის დირექტორი, გარდა იმ შემთხვევისა, როდესაც დისციპლინური სახდელის 5-დან 10 დღემდე დათხოვნის დაკისრების გამო, კონკრეტული დისციპლინური გადაცდომა განსახილველად უნდა გადაეცეს დისციპლინურ კომიტეტს.</li>
                    <li>წინამდებარე შინაგანაწესის მე-16 მუხლის მე&ndash;2 პუნქტის &bdquo;ვ&ldquo;(5-დან 10 დღემდე ვადით) და ,,თ&ldquo; (გარიცხვა) ქვეპუნქტებით გათვალისწინებული დისციპლინური სახდელების გამოყენებაზე გადაწყვეტილებას იღებს დისციპლინური კომიტეტი ფარული კენჭისყრით. შინაგანაწესის მე-16 მუხლის პირველიპუნქტის,,ი.გ", ,,ი.ვ&ldquo;, ,,ი.კ&ldquo; და ,,ი.ლ&ldquo; ქვეპუნქტით განსაზღვრული ნივთის/ნივთების სკოლის ტერიტორიაზე მოსწავლის მიერ გამოყენებისა ან/და ამავე პუნქტის &bdquo;მ&ldquo; ქვეპუნქტით განსაზღვრული დისციპლინური გადაცდომის ჩადენის შემთხვევებს განიხილავს დისციპლინური კომიტეტი.</li>
                    <li>დაუშვებელია დაწყებით და საბაზო საფეხურზე მოსწავლის სკოლიდან გარიცხვა.</li>
                    <li>მოსწავლის მიმართ დისციპლინური სახდელის გამოყენების, აგრეთვე დისციპლინური დევნის დაწყების შესახებ დაუყოვნებლივ უნდა ეცნობოს მის მშობელს ან/და კანონიერ წარმომადგენელს. მოსწავლეს უფლება აქვს მასთან დაკავშირებული საკითხის გადაწყვეტისას მშობელთან ან/და კანონიერ წარმომადგენელთან ერთად წარდგეს.</li>
                    <li>დისციპლინური სახდელის დადებამდე მოსწავლეს, მისი მშობლის/კანონიერი წარმომადგენლის თანდასწრებით, უნდა ჩამოერთვას წერილობითი ახსნა-განმარტება. თუ მხარე უარს ამბობს ახსნა-განმარტების მიცემაზე ან შეუძლებელია მისი ჩამორთმევა, შედგება აქტი, რომელსაც ხელს მოაწერენ დამსწრე პირები.</li>
                    <li>მშობლის/კანონიერი წარმომადგენლის წერილობითი ფორმით გაცხადებული უარი მოსწავლისათვის ახსნა-განმარტების ჩამორთმევის თაობაზე, არ საჭიროებს ამ მუხლის მე-7 პუნქტით გათვალისწინებული აქტის შედგენას.</li>
                    <li>საგაკვეთილო პროცესის ჩაშლის ან ჩაშლის მცდელობის შემთხვევაში, მოსწავლის გაკვეთილიდან გაძევების უფლება აქვს მასწავლებელს. ასეთ შემთხვევაში მასწავლებელი იძახებს სკოლის მანდატურს/უფლებამოსილ პირს/სკოლის ადმინისტრაციის წარმომადგენელს და მოსწავლეს მათი თანხლებით ატოვებინებს საგაკვეთილო პროცესს.</li>
                    <li>მანდატური/უფლებამოსილი პირი/სკოლის ადმინისტრაცია ფაქტის შესახებ ინფორმაციას აწვდის მოსწავლის მშობელს/კანონიერ წარმომადგენელს და უზრუნველყოფს მის აღრიცხვას.</li>
                    <li>გაკვეთილის დასრულებამდე/მოსწავლის მშობლის/კანონიერი წარმომადგენლის მოსვლამდე მოსწავლე უნდა იმყოფებოდეს მანდატურის/ უფლებამოსილი პირის/სკოლის ადმინისტრაციის მეთვალყურეობის ქვეშ.</li>
                    <li>სკოლის ადმინისტრაციის წარმომადგენლის, მასწავლებლის, მანდატურისა და სკოლაში დასაქმებული სხვა პირების კანონიერი მოთხოვნისადმი მოსწავლის დაუმორჩილებლობის შემთხვევაში მანდატური/უფლებამოსილი პირი/სკოლის ადმინისტრაცია ვალდებულია აღნიშნულის შესახებ დაუყოვნებლივ აცნობოს მოსწავლის მშობელს/კანონიერ წარმომადგენელს.</li>
                    <li>მოსწავლის მშობლის/კანონიერი წარმომადგენლის სკოლაში გამოცხადებამდე მანდატური/უფლებამოსილი პირი/სკოლის ადმინისტრაცია ვალდებულია მოსწავლე არ დატოვოს უმეთვალყურეოდ.</li>
                    <li>იმ შემთხვევაში, თუ მოსწავლე სკოლის შენობას დატოვებს მოსწავლის მშობლის/კანონიერი წარმომადგენლის სკოლაში გამოცხადებამდე, მანდატური/უფლებამოსილი პირი/სკოლის ადმინისტრაცია ვალდებულია აღნიშნულის შესახებ დაუყოვნებლივ შეატყობინოს მოსწავლის მშობელს/კანონიერ წარმომადგენელს, ხოლო იმ შემთხვევაში, თუ შეუძლებელია მოსწავლის ადგილსამყოფლის დადგენა ან/და მოსწავლესთან დაკავშირება, მანდატური/უფლებამოსილი პირი/სკოლის ადმინისტრაცია დაუყოვნებლივ ატყობინებს საქართველოს შინაგან საქმეთა სამინისტროს სსიპ &ndash; &bdquo;112&ldquo;-ს;</li>
                    <li>მოსწავლისთვის სკოლისათვის სასარგებლო საქმიანობის დაკისრებისას უნდა განისაზღვროს: სასარგებლო საქმიანობის შინაარსი; მისი განხორციელების წესი და პირობები; სასარგებლო საქმიანობის განხორციელების ადგილი სკოლის ტერიტორიის ფარგლებში; მისი ხანგრძლივობა, რომელიც დაწყებითი საფეხურის მოსწავლისათვის არ უნდა აღემატებოდეს დღეში 1 საათს, საბაზო საფეხურის მოსწავლისათვის &ndash; დღეში 2 საათს და საშუალო საფეხურის მოსწავლისათვის &ndash; დღეში 3 საათს; უფლებამოსილი პირის ვინაობა, რომელიც უშუალო ზედამხედველობას გაუწევს მოსწავლის მიერ სკოლისათვის სასარგებლო საქმიანობის განხორციელებას. სკოლისათვის სასარგებლო საქმიანობა მოსწავლეს შეიძლება დაეკისროს მშობლის თანხმობით.</li>
                    <li>სკოლა ვალდებულია, მოსწავლისათვის სასარგებლო საქმიანობის დაკისრებისას გაითვალისწინოს მოსწავლის ჯანმრთელობის მდგომარეობა, სასარგებლო საქმიანობის რისკები და სასარგებლო საქმიანობის მიზნები.</li>
                    <li>სკოლა ვალდებულია მიიღოს ზომები, რათა დისციპლინურმა სახდელმა არ გამოიწვიოს მოსწავლის არსებითი ჩამორჩენა სასწავლო პროცესისგან.</li>
                    <li>მოსწავლისთვის დისციპლინური სახდელის დაკისრების შესახებ გადაწყვეტილება ფორმდება სკოლის დირექტორის ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტით.</li>
                    <li>მოსწავლეს დისციპლინური სახდელი ვადაზე ადრე შეიძლება მოეხსნას თუ 1 თვის ვადაში:</li>
                    </ol>
                    <p>ა) მან არ ჩაიდინა ახალი გადაცდომა;</p>
                    <p>ბ) გამოირჩევა სანიმუშო ქცევით;</p>
                    <p>გ) აქვს გარკვეული მიღწევები.</p>
                    <ol>
                    <li>დისციპლინური სახდელის ვადაზე ადრე მოხსნის თაობაზე გადაწყვეტილებას იღებს სკოლის დირექტორი. გადაწყვეტილება ფორმდება სკოლის დირექტორის ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტით.</li>
                    <li>მოსწავლის მიმართ დისციპლინური წარმოების დაწყება არ შეიძლება გადაცდომის ჩადენის დღიდან 3 (სამი) თვის გასვლის შემდეგ.</li>
                    <li>მოსწავლის დისციპლინური სახდელის მოქმედების ვადაა 3 თვე. თუ მოსწავლემ სახდელის დაკისრებიდან 3 თვის ვადაში არ ჩაიდინა ახალი დისციპლინური გადაცდომა, რისთვისაც არ დაკისრებია ახალი დისციპლინური სახდელი, იგი ითვლება დისციპლინური სახდელის არ მქონედ.</li>
                    <li>დისციპლინური სახდელის დაკისრების ან არდაკისრების შესახებ ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტი გამოიცემა გადაცდომის თაობაზე ინფორმაციის მიღებიდან არაუგვიანეს 1 (ერთი) თვისა.</li>
                    <li>თუ საქმისათვის არსებითი მნიშვნელობის მქონე გარემოებათა დადგენისათვის აუცილებელია ინდივიდუალური ადმინისტრაციულ-სამართლებრივი აქტის გამოსაცემად კანონმდებლობით გათვალისწინებულზე მეტი ვადა, ამ შემთხვევაში შესაძლებელია ადმინისტრაციული წარმოების გაზრდა, რომელიც არ უნდა აღემატებოდეს წარმოების დაწყებიდან 3 თვეს.</li>
                    <li>მოსწავლის მიმართ დისციპლინური წარმოება მიმდინარეობს საქართველოს განათლებისა და მეცნიერების მინისტრის 2014 წლის 30 დეკემბრის №167/ნ ბრძანებით დამტკიცებული მასწავლებელთა დისციპლინური წარმოების წესის შესაბამისად.</li>
                    <li>კონკრეტული მოსწავლის მიმართ დისციპლინური წარმოება შეწყდება თუ დადგინდა, რომ დისციპლინური გადაცდომა ჩადენილია მოსწავლის არაბრალეული ქმედებით.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 20. სკოლის მანდატურის მიერ შედგენილ დარღვევის აღრიცხვის ფურცელზე რეაგირების ვადები და პირობები</strong></p>
                    <ol>
                    <li>სკოლის დირექცია, მანდატურისაგან მოსწავლის მიერ სავარაუდო დარღვევის შესახებ დარღვევის აღრიცხვის ფურცლის მიღებიდან არაუგვიანეს მომდევნო სამუშაო დღისა ვალდებულია უზრუნველყოს სავარაუდო დარღვევის ფაქტის შესწავლის დაწყება და განახორციელოს პრევენციული ღონისძიებები.</li>
                    <li>ამ მუხლის პირველი პუნქტით განსაზღვრული დარღვევის ფაქტის შესწავლა უნდა განხორციელდეს 7 (შვიდი) სამუშაო დღის ვადაში, ხოლო დარღვევის ფაქტის დადასტურების შემთხვევაში, სკოლის დირექცია ვალდებულია უზრუნველყოს მოსწავლის მიმართ სკოლის შინაგანაწესით გათვალისწინებული ღონისძიებების განხორციელება.</li>
                    <li>სავარაუდო დარღვევის ფაქტის დადგენის შემთხვევაში, მისი სიმძიმიდან გამომდინარე, სკოლის დირექტორი, თავის ინდივიდუალურ ადმინისტრაციულ სამართლებრივი აქტით დამრღვევ პირს (მოსწავლეს) უფარდებს ამ შინაგანაწესით გათვალისწინებულ დისციპლინურ სახდელს ან საქმეს მოკვლევისათვის გადაცემს სკოლის დისციპლინურ კომიტეტს.</li>
                    <li>კლასის დამრიგებელი, სკოლის დირექცია, დისციპლინური კომიტეტი ვალდებულია, ღონისძიების განხორციელებიდან არაუგვიანეს მომდევნო სამუშაო დღისა, სკოლის მანდატურს/უფლებამოსილ პირს წერილობით აცნობოს ამ უკანასკნელის მიერ წარმოდგენილი დარღვევის აღრიცხვის ფურცელთან დაკავშირებით გატარებული ღონისძიებების შესახებ.</li>
                    <li>ამ მუხლით დადგენილი წესის დარღვევა წარმოადგენს დისციპლინურ გადაცდომას და შესაბამისად, დისციპლინური სახდელის შეფარდების საფუძველს.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 21 . დასაქმებულის და მოსწავლის მატერიალური პასუხისმგებლობა</strong></p>
                    <ol>
                    <li>დასაქმებული და მოსწავლე პასუხისმგებელი არიან სკოლის ქონების სათანადო გამოყენებასა და დაცვაზე.</li>
                    <li>დასაქმებული და მოსწავლე ვალდებულია:</li>
                    </ol>
                    <p>ა) ქონება გამოიყენონ მხოლოდ დანიშნულების მიხედვით;</p>
                    <p>ბ) ქონების ნებისმიერი სახის დაზიანების, განადგურების ან დაკარგვის შესახებ აცნობონ სკოლის დირექტორს;</p>
                    <ol start="3">
                    <li>დასაქმებული, მოსწავლე და კანონით განსაზღვრულ შემთხვევებში მშობელი/კანონიერი წარმომადგენელი ვალდებულია აანაზღაურონ სკოლისთვის მიყენებული მატერიალური ზიანი საქართველოს სამოქალაქო კოდექსით დადგენილი წესით.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 22.&nbsp; განცხადებისა და საჩივრის განხილვის წესი&nbsp; </strong></p>
                    <p>სკოლაში შემოსული განცხადება/საჩივარი რეგისტრაციაში უნდა გატარდეს და მათზე რეაგირება უნდა განხორციელდეს კანონმდებლობით დადგენილი წესით.</p>
                    <p>&nbsp;</p>
                    <p><strong>მუხლი 23. სასწავლო პროცესის მარეგულირებელი ნორმები</strong></p>
                    <ol>
                    <li>სკოლაში სასწავლო წლის, არდადეგების დაწყებისა და დამთავრების დრო განისაზღვრება საქართველოს განათლების, მეცნიერების, კულტურისა და სპორტის მინისტრის ბრძანების შესაბამისად.</li>
                    <li>სკოლაში სასწავლო პროცესის დაწყებისა და დასრულების დრო განისაზღვრება დირექტორის ბრძანებით.</li>
                    <li>აკრძალულია სასწავლო პროცესის მიმდინარეობის დროს გარეშე პირთა სკოლის შენობაში ან სკოლის ტერიტორიაზე შესვლა სკოლის დირექციასთან შეთანხმების გარეშე.</li>
                    <li>მოსწავლის მშობლის/კანონიერი წარმომადგენლის მიღების დღეებია ყოველი პარასკევი 13:00 საათიდან 17:00 საათამდე (გარდა წინასწარ დაბარებული პირებისა). სხვა დღეებში მოსწავლის მშობელს/კანონიერ წარმომადგენელს ეზღუდება თავისუფალი გადაადგილება სკოლის შენობაში, სკოლის დირექციასთან შეთანხმების გარეშე. ნებისმიერ საკითხზე დირექციასთან ვიზიტი დასაშვებია ყოველდღე (ორშაბათი-პარასკევი) 15:00 საათიდან 17:00 საათამდე.</li>
                    <li>ამ მუხლის მე-4 პუნქტი არ ვრცელდება სპეციალური საგანმანათლებლო საჭიროების მქონე მოსწავლის მშობელზე/კანონიერ წარმომადგენელზე, რომლებიც სკოლის შენობაში საჭიროებისამებრ დაიშვებიან სასწავლო პროცესის განმავლობაში ნებისმიერ დროს.</li>
                    <li>მასწავლებელი ვალდებულია სკოლაში გამოცხადდეს სკოლის საათობრივი ბადით გათვალისწინებული მის მიერ ჩასატარებელი გაკვეთილის დაწყებამდე 15 წუთით ადრე.</li>
                    <li>სკოლის ყველა მასწავლებელი, მიუხედავად მათი დატვირთვისა, ვალდებულია სკოლაში იყოს ყოველ პარასკევს 13:00 საათიდან 17:00 საათამდე და მიაწოდოს ინფორმაცია სკოლაში მოსულ მშობლებს/კანონიერ წარმომადგენლებს მათი შვილების აკადემიური მოსწრების შესახებ.</li>
                    <li>დამრიგებელი ვალდებულია თავისი სადამრიგებლო კლასისათვის გაკვეთილების ცხრილით განსაზღვრული გაკვეთილების დასრულებამდე იმყოფებოდეს სკოლაში.</li>
                    <li>სკოლაში იზღუდება მოსწავლის გაკვეთილებიდან გათავისუფლება. მოსწავლის გაკვეთილებიდან გათავისუფლება შესაძლებელია სკოლის დირექტორის/მოადგილის გადაწყვეტილებით, რომელიც ეფუძნება მოსწავლის მშობლის/კანონიერი წარმომადგენლის თხოვნას ან/და სკოლის ექიმის რეკომენდაციას გაშვების შესახებ, რომელიც დაუყოვნებლივ უნდა ეცნობოს მოსწავლის მშობელს/კანონიერ წარმომადგენელს.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol>
                    <li>საგანმანათლებლო პროცესის ორგანიზებასთან დაკავშირებული სხვა საკითხები რეგულირდება ეროვნული სასწავლო გეგმისა და მოქმედი კანონმდებლობის შესაბამისად.</li>
                    </ol>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>მუხლი 24. სკოლაში ჩაცმულობის წესი</strong></p>
                    <ol>
                    <li>სკოლაში დასაქმებულისათვის სავალდებულოა ჰქონდეს მოწესრიგებული იერსახე და სამუშაო დღეებში ატაროს საქმიანი გარემოსათვის შესაფერისი სამოსი.</li>
                    <li>მოსწავლე ვალდებულია სკოლაში გამოცხადდეს სასწავლო გარემოსთან შესაბამისი სადა, მოწესრიგებული ჩაცმულობითა და იერსახით.</li>
                    </ol>
                    </div>
            </div>
        </div>
            {/* <Covid toggleDark={toggleDark}/> */}
        </div>
    )
}

export default laws;